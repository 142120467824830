import {
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
} from '@mui/material';
import Autocomplete from '@components/form/Autocomplete';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { STATES_GERMANY } from '@lib/autocompleteData';
import {
  Autocomplete as GoogleAutocomplete,
  useJsApiLoader,
} from '@react-google-maps/api';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import getConfig from 'next/config';
import { DebounceInput } from '@components/form/DebounceInput';

const { publicRuntimeConfig } = getConfig();

export default function RegionFilters(props) {
  const {
    onChange,
    addressCity,
    addressState,
    filters,
    setFilters,
  } = props;
  const { t, i18n } = useTranslation();
  const [mapLanguage] = useState(LOCALES[i18n.language]);
  const { isLoaded: isLibraryLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: publicRuntimeConfig.GOOGLE_API_KEY,
    libraries: ['places'],
    language: mapLanguage,
  });
  const [autocompleteInstance, setAutocompleteInstance] = useState(null);
  const [addressSearchInput, setAddressSearchInput] = useState('');

  useEffect(() => {
    // no-op
  }, [isLibraryLoaded]);

  const onLoad = (values) => {
    setAutocompleteInstance(values);
  };

  const handlePlaceChanged = useCallback(() => {
    if (autocompleteInstance === null) {
      return;
    }
    const place = autocompleteInstance.getPlace();
    setAddressSearchInput(place.name);
    const newFilters = {
      ...filters,
    };

    place.address_components.forEach((item) => {
      item.types.forEach((type) => {
        if (type === 'locality') {
          newFilters.addressCity = item.long_name;
        }
        if (type === 'administrative_area_level_1') {
          newFilters.addressState = item.long_name;
        }
        if (type === 'country') {
          // no-op
        }
      });
    });

    setFilters(newFilters);
  }, [setFilters, autocompleteInstance, filters]);

  if (!isLibraryLoaded) {
    return (
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Skeleton />
        </Grid>
        <Grid item md={6} xs={12}>
          <Skeleton />
        </Grid>
        <Grid item md={6} xs={12}>
          <Skeleton />
        </Grid>
        <Grid item md={12}>
          <Skeleton />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <GoogleAutocomplete
          onLoad={onLoad}
          onPlaceChanged={handlePlaceChanged}
        >
          <TextField
            fullWidth
            InputProps={{
              endAdornment: addressSearchInput && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setAddressSearchInput('');
                      setFilters({
                        ...filters,
                        addressCity: undefined,
                        addressState: undefined,
                      });
                    }}
                    size="small"
                  >
                    <FiX />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            name="search"
            onChange={((e) => setAddressSearchInput(e.target.value))}
            size="small"
            value={addressSearchInput}
          />
        </GoogleAutocomplete>
      </Grid>
      <Grid item md={6} xs={12}>
        <DebounceInput
          element={TextField}
          fullWidth
          InputProps={{
            endAdornment: addressCity && (
              <InputAdornment position="end">
                <IconButton onClick={() => onChange('addressCity', undefined)} size="small">
                  <FiX />
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={t('components.filters.RegionFilters.city')}
          onChange={(e) => onChange('addressCity', e.target.value)}
          size="small"
          value={addressCity ?? ''}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Autocomplete
          fullWidth
          items={STATES_GERMANY}
          label={t('components.filters.RegionFilters.state')}
          onChange={(_, newValue) => onChange('addressState', newValue)}
          onInputChange={(e) => onChange('addressState', e.target.value)}
          size="small"
          value={addressState ?? ''}
        />
      </Grid>
      {/* <Grid item sx={{ display: 'none' }} xs={12}>
        <Autocomplete
          fullWidth
          items={COUNTRIES}
          label={t('components.filters.RegionFilters.country')}
          onChange={(_, newValue) => setCountryInput(newValue)}
          onInputChange={(e) => setCountryInput(e.target.value)}
          size="small"
          value={countryInput}
        />
      </Grid> */}
    </Grid>
  );
}

const LOCALES = {
  de: 'de-DE',
  en: 'en-US',
};
